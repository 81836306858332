define("ember-svg-jar/inlined/copy-paste", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>copy-paste</title><path d=\"M9.5 21.5h-8a1 1 0 01-1-1v-16a1 1 0 011-1h2M13.5 3.5h2a1 1 0 011 1V8\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"8.5\" cy=\"1.999\" r=\"1.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.915 2.5H12.5a1 1 0 011 1v1a1 1 0 01-1 1h-8a1 1 0 01-1-1v-1a1 1 0 011-1h2.585M22.5 22.5a1 1 0 01-1 1h-9a1 1 0 01-1-1V11a1 1 0 011-1h7.086a1 1 0 01.707.293l1.914 1.914a1 1 0 01.293.707zM14.5 14.499h5M14.5 17.499h5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
});