define("ember-svg-jar/inlined/preview-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M438 88c23.188 0 42 18.804 42 42 0 13.212-6.213 25.638-16.8 33.6L272.8 306.4a28.088 28.088 0 01-33.6 0L48.8 163.6C38.224 155.638 32 143.213 32 130c0-23.196 18.804-42 42-42h364zM222.4 328.8a55.913 55.913 0 0067.2 0L480 186v182c0 30.888-25.112 56-56 56H88c-30.931 0-56-25.112-56-56V186l190.4 142.8z\"/>",
    "attrs": {
      "style": "transform-origin:.5em .5em",
      "class": "svg-inline--fa fa-envelope fa-2x",
      "data-prefix": "fas",
      "data-icon": "envelope",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
});