define("ember-svg-jar/inlined/component-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 8.461V3.467a.967.967 0 00-.967-.967H3.467a.967.967 0 00-.967.967v8.7a.967.967 0 00.967.966H7.01\"/><path d=\"M7.011 7.322a1.525 1.525 0 100-3.05 1.525 1.525 0 000 3.05zm.88 3.049H4.397a2.614 2.614 0 014.395-1.913M11.2 4.917h2.9M11.2 6.85h3.867M13.133 17a3.867 3.867 0 100-7.733 3.867 3.867 0 000 7.733z\"/><path d=\"M14.857 12.009l-1.872 2.496a.486.486 0 01-.73.052l-.966-.967\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});