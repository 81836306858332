define("ember-svg-jar/inlined/component-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.783 4H4.217C3.545 4 3 4.545 3 5.217v9.13c0 .673.545 1.218 1.217 1.218h11.566c.672 0 1.217-.545 1.217-1.217v-9.13C17 4.544 16.455 4 15.783 4zM3 7.043h14m-8.522 5.479V9.478m-.608 0h1.217m-3.652 3.044V9.478m1.217 3.044V9.478m-1.217 1.826h1.217\"/><path d=\"M13.348 9.478v2.435a.609.609 0 00.608.609h.61m-2.436 0V9.478L11.217 11l-.913-1.522v3.044M5.13 5.674a.152.152 0 110-.304m0 .304a.152.152 0 100-.304m1.827.304a.152.152 0 110-.304m0 .304a.152.152 0 100-.304m1.826.304a.152.152 0 110-.304m0 .304a.152.152 0 100-.304\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});