define("ember-svg-jar/inlined/component-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.546 5.227H3.455C2.65 5.227 2 5.878 2 6.682v7.636c0 .803.651 1.455 1.455 1.455h13.09c.804 0 1.455-.652 1.455-1.455V6.682c0-.804-.651-1.455-1.454-1.455z\"/><path d=\"M8.228 7.41H4.592v3.636h3.636V7.409zm-3.636 5.817h3.226m4.364-5.454h3.082m-3.082 5.454h3.082M10.939 10.5h4.325\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});