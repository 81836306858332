define("ember-svg-jar/inlined/tree-cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.532 14.733h1.777c.21 0 .411-.087.56-.24a.838.838 0 00.231-.582v-.822a.838.838 0 00-.231-.58.777.777 0 00-.56-.241h-1.778a9.952 9.952 0 00-.791-2.401l1.257-1.305a.838.838 0 00.232-.581.838.838 0 00-.232-.581l-1.122-1.162a.777.777 0 00-.56-.24.777.777 0 00-.559.24l-1.258 1.306a9.083 9.083 0 00-2.31-.822v-1.85a.838.838 0 00-.232-.581.777.777 0 00-.56-.24h-.791a.777.777 0 00-.56.24.838.838 0 00-.232.581V6.72a9.088 9.088 0 00-2.311.822L8.244 6.238a.777.777 0 00-.56-.24.777.777 0 00-.559.24L6.005 7.4a.838.838 0 00-.231.58c0 .218.083.427.231.581l1.258 1.306a9.969 9.969 0 00-.79 2.4H4.691a.777.777 0 00-.56.241.838.838 0 00-.232.581v.822c0 .218.084.427.232.581.149.154.35.24.56.24h1.777a9.97 9.97 0 00.791 2.401l-1.258 1.307a.838.838 0 00-.232.58c0 .218.084.427.232.581l1.123 1.163c.148.154.35.24.56.24.21 0 .41-.086.559-.24L9.5 19.458c.733.378 1.51.654 2.312.822v1.849c0 .217.084.426.232.58.149.155.35.241.56.241h.791c.21 0 .411-.086.56-.24a.838.838 0 00.231-.581v-1.845a9.102 9.102 0 002.311-.822l1.259 1.306c.148.154.35.24.56.24.21 0 .41-.086.559-.24l1.119-1.162a.838.838 0 00.231-.581.838.838 0 00-.231-.581l-1.258-1.307c.365-.762.633-1.57.795-2.404z\" stroke=\"#6B7280\"/><path d=\"M9.439 13.501c0 .98.375 1.921 1.043 2.615A3.495 3.495 0 0013 17.199c.944 0 1.85-.39 2.518-1.083A3.771 3.771 0 0016.56 13.5c0-.98-.375-1.921-1.043-2.615A3.495 3.495 0 0013 9.803c-.945 0-1.85.39-2.518 1.083a3.771 3.771 0 00-1.043 2.615v0z\"/>",
    "attrs": {
      "viewBox": "0 0 26 27",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});