define("ember-svg-jar/inlined/message-draft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"scale(2)\"><defs><style>.a{fill:none;stroke-linecap:round;stroke-linejoin:round}</style></defs><title>email-action-edit</title><path class=\"a\" d=\"M15.7 22.3l-4.2 1.2 1.2-4.2 7.179-7.179a2.121 2.121 0 013 3zM18.979 13.021l3 3M12.7 19.3l3 3M13.5 14.5H2A1.5 1.5 0 01.5 13V2A1.5 1.5 0 012 .5h18A1.5 1.5 0 0121.5 2v6.5\"/><path class=\"a\" d=\"M21.067.946L11 9 .933.946\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "currentColor",
      "viewBox": "0 0 48 48"
    }
  };
});