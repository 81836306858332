define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M479.3 370.625l-20.344 88.2c-2.858 12.469-13.816 21.192-26.652 21.192C211.55 480 32 300.45 32 79.688c0-12.827 8.723-23.8 21.192-26.643l88.2-20.344c12.845-2.977 25.97 3.69 31.308 15.855l40.705 94.938c4.758 11.182 1.549 24.21-7.857 31.893l-47.11 37.827C188.17 273.78 237.415 323.024 298 352.774l38.57-47.075c7.602-9.432 20.729-12.696 31.911-7.853l94.938 40.696c11.419 6.007 18.856 19.307 15.881 32.082z\"/>",
    "attrs": {
      "style": "transform-origin:.5em .5em",
      "class": "svg-inline--fa fa-phone fa-2x",
      "data-prefix": "fas",
      "data-icon": "phone",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
});