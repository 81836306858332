define("ember-svg-jar/inlined/document-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.394 23.413H1.538A1.038 1.038 0 01.5 22.375V1.625A1.038 1.038 0 011.538.587H17.1a1.038 1.038 0 011.038 1.038v8.3\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M18.923 13.329l1.33 3.512h2.589a.635.635 0 01.448 1.108l-2.247 1.567 1.245 2.859a.678.678 0 01-.966.848l-3.009-1.692-3.013 1.692a.678.678 0 01-.966-.848l1.245-2.859-2.247-1.567a.634.634 0 01.444-1.108h2.59l1.334-3.512a.688.688 0 011.223 0z\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"9.324\" cy=\"8.005\" r=\"1.731\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M12.433 13.507a3.209 3.209 0 00-6.219 0\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"9.324\" cy=\"9.038\" r=\"5.439\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M3.857 20.158h5.706M5.52 17.318h4.043\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
});