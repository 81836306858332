define("ember-svg-jar/inlined/component-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.988 7.26H3l6.842-4.217a.323.323 0 01.316 0l6.83 4.218z\"/><path d=\"M12.435 7.26L10 3 7.565 7.26m1.522 5.48V9.52m1.826 3.22V9.52M9.087 17v-1.826h1.826V17m-5.412-4.115l.238-.572a2.307 2.307 0 011.759-1.37.304.304 0 01.371.274v1.522m-3.043 1.218h2.13\"/><path d=\"M4.844 13.75a1.26 1.26 0 011.266-1.01h8.15a.31.31 0 01.305.358 2.434 2.434 0 01-2.435 2.076H6.043a1.218 1.218 0 01-1.199-1.424zM3 7.26v.153a2.283 2.283 0 104.565 0v-.152a2.435 2.435 0 004.87 0v.152a2.283 2.283 0 004.565 0v-.152\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});