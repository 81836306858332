define("ember-svg-jar/inlined/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M22.79 14.52a1 1 0 010 1.41L15.72 23a1 1 0 01-1.41 0L1.21 9.91a2.84 2.84 0 01-.7-1.71V1.71a1 1 0 011-1H8a2.78 2.78 0 011.71.7z\"/><circle cx=\"5.5\" cy=\"5.71\" r=\"2\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
});