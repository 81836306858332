define("ember-svg-jar/inlined/component-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.991 14.096V16l3.809-3.491-3.809-3.492v1.905H9.548v3.174h4.443z\"/><path d=\"M8.278 12.191H4.152a.952.952 0 01-.952-.952V4.257a.952.952 0 01.952-.953h11.426a.952.952 0 01.952.953v4.126\"/><path d=\"M16.256 3.587L9.866 8.7 3.474 3.587\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});