define("ember-svg-jar/inlined/message-stat-sent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"scale(2)\"><defs><style>.a{fill:none;stroke-linecap:round;stroke-linejoin:round}</style></defs><title>send-email-3</title><path class=\"a\" d=\"M23.5.5L9 15M13.5 23.5L9 15 .5 10.5l23-10-10 23z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 48 48",
      "stroke": "currentColor",
      "stroke-width": "2"
    }
  };
});