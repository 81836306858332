define("ember-svg-jar/inlined/folder-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-image</title><path d=\"M22 4.5H9a1 1 0 01-.72-.306L6.938 2.806a1 1 0 00-.72-.306H2.786A1.286 1.286 0 001.5 3.786V15.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><rect x=\"4.5\" y=\"7.5\" width=\"18\" height=\"14\" rx=\"1\" ry=\"1\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M8 18.5l2.45-3.151a1 1 0 011.274-.206L13.5 16.5l1.663-2.14a1 1 0 011.587.067L19.5 18.5z\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"9.5\" cy=\"11\" r=\"1.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
});