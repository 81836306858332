define("ember-svg-jar/inlined/resize-expand-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-expand-arrow</title><path fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M21.5 4.5v15M4.5 2.5h15M19.5 21.5H3a.5.5 0 01-.5-.5V4.5\"/><rect x=\".5\" y=\".5\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><rect x=\"19.5\" y=\".5\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><rect x=\"19.5\" y=\"19.5\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10.5 13.5h-8V21a.5.5 0 00.5.5h7.5zM10.5 13.5l5-5M11.5 8.5h4v4\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
});