define("ember-svg-jar/inlined/hammer-wench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hammer-wench</title><path d=\"M18.25 2.251c-1.061 1.061-3.04.96-4.75-.75L12 3l8 8 3.5-3.5zM11.939 9.94L8.146 6.148A4 4 0 002.854.855L5 3v2H3L.854 2.855a4 4 0 005.292 5.293l3.793 3.792M12.061 14.062l3.793 3.793a4 4 0 005.292 5.293L19 21v-2h2l2.146 2.147a4 4 0 00-5.292-5.293l-3.793-3.793\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15.439 6.44l-14.5 14.5a1.5 1.5 0 002.122 2.122l14.5-14.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
});