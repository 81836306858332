define("ember-svg-jar/inlined/preview-caret-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M119.775 124.225l112 111.213c5.512 6.212 8.225 13.387 8.225 19.775s-2.734 14.332-8.203 19.8l-112 111.213c-8.012 8.012-20.046 10.413-30.52 6.075S72 379.287 72 367.213V144c0-11.323 6.808-21.543 17.281-25.883s22.531-1.916 30.494 6.108z\"/>",
    "attrs": {
      "style": "transform-origin:.25em .5em",
      "class": "svg-inline--fa fa-caret-right fa-2x",
      "data-prefix": "fas",
      "data-icon": "caret-right",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 256 512"
    }
  };
});