define("ember-svg-jar/inlined/enspire-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.5 37C28.717 37 37 28.717 37 18.5S28.717 0 18.5 0 0 8.283 0 18.5 8.283 37 18.5 37z\" fill=\"#D82B96\"/><path d=\"M28.84 20.287H13.833c.564 2.727 2.707 4.396 5.79 4.396 2.02 0 3.506-.615 4.834-1.878a.32.32 0 01.464.023l2.723 3.197a.32.32 0 01.001.414c-1.879 2.185-4.637 3.333-8.173 3.333-6.994 0-11.543-4.762-11.543-11.275 0-6.553 4.624-11.276 10.79-11.276 5.94 0 10.528 4.314 10.528 11.357 0 .403-.051.943-.09 1.416a.319.319 0 01-.319.293zm-15.083-3.705h9.964c-.415-2.768-2.332-4.56-4.964-4.56-2.669 0-4.586 1.75-5 4.56z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "37",
      "height": "37",
      "viewBox": "0 0 37 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});