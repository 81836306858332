define("ember-svg-jar/inlined/preview-caret-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M291.775 247.775l-111.212 112C174.35 365.287 167.175 368 160 368s-14.332-2.734-19.801-8.203l-111.213-112c-8.79-7.985-11.187-20.06-6.853-30.472S36.686 200 48 200h223.213c11.322 0 21.542 6.808 25.882 17.281s2.73 22.531-5.32 30.494z\"/>",
    "attrs": {
      "style": "transform-origin:.3125em .5em",
      "class": "svg-inline--fa fa-caret-down fa-2x",
      "data-prefix": "fas",
      "data-icon": "caret-down",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 320 512"
    }
  };
});