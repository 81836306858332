define("ember-svg-jar/inlined/component-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.387 5.435a.609.609 0 01.178.43v10.526a.608.608 0 01-.608.609H4.608A.609.609 0 014 16.391V3.61A.609.609 0 014.609 3H12.7c.161 0 .316.063.43.177l2.257 2.258zm-4.083.608h2.435m-7.913 5.478h7.913m-7.913 1.826h7.913m-7.913 1.826h7.913m-2.435-7.304h2.435m-2.435 1.826h2.435\"/><path d=\"M5.826 6.043v-.609h3.652v.609m-1.826-.609v4.261m-1.217 0H8.87\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});