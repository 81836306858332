define("ember-svg-jar/inlined/tree-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.3 11.807l6.919-6.602c.102-.097.224-.174.357-.227a1.147 1.147 0 011.199.232l6.925 6.597m-2.2-2.096V18.1h-11V9.708m7.7 8.392v-3.146a2.05 2.05 0 00-.645-1.484A2.255 2.255 0 0011 12.856c-.584 0-1.143.221-1.556.614a2.05 2.05 0 00-.644 1.484V18.1\"/>",
    "attrs": {
      "viewBox": "0 0 22 23",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});