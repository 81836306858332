define("ember-svg-jar/inlined/component-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.696 10H17m-4.87-2.435L9.696 10l2.434 2.435M8.478 4.217h4.566a.304.304 0 01.304.304v1.522m0 7.913v1.522a.304.304 0 01-.305.305H8.479\"/><path d=\"M3 15.614a.304.304 0 00.238.297l4.87 1.082a.306.306 0 00.37-.297V3.304a.304.304 0 00-.37-.297l-4.87 1.082A.304.304 0 003 4.386v11.228z\"/><path d=\"M5.435 10a.913.913 0 101.825 0 .913.913 0 00-1.825 0z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});