define("ember-tui-editor/components/tui-editor", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/debug", "@glimmer/component", "@glimmer/tracking", "ember-tui-editor/utils/load-locale-file"], function (_exports, _component, _templateFactory, _object, _debug, _component2, _tracking, _loadLocaleFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert this.setupEditor}}
    {{will-destroy this.teardownEditor}}
    ...attributes
  >
    {{yield}}
  </div>
  
  {{#if this.editor}}
    {{#each this.tuiOptionsDescriptors as |desc|}}
      {{did-update (fn this.updateOption desc) (get this.args (get desc 0))}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "/QQaoiNt",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"setupEditor\"]]],null],[4,[38,1],[[30,0,[\"teardownEditor\"]]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"editor\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"tuiOptionsDescriptors\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,6],[[28,[37,7],[[30,0,[\"updateOption\"]],[30,2]],null],[28,[37,8],[[30,0,[\"args\"]],[28,[37,8],[[30,2],0],null]],null]],null]],[1,\"\\n\"]],[2]],null]],[]],null]],[\"&attrs\",\"desc\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\",\"if\",\"each\",\"-track-array\",\"did-update\",\"fn\",\"get\"]]",
    "moduleName": "ember-tui-editor/components/tui-editor.hbs",
    "isStrictMode": false
  });
  let TuiEditor = _exports.default = (_class = class TuiEditor extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "editor", _descriptor, this);
      // here we use a syntax like <property>:<method>:<tui option> to update such property (optional)>
      _defineProperty(this, "tuiOptions", ['previewStyle:changePreviewStyle', 'editType:changeMode:initialEditType', 'height:setHeight', 'minHeight:setMinHeight', 'language', 'useCommandShortcut', 'usageStatistics', 'toolbarItems', 'hideModeSwitch', 'viewer', 'value:setMarkdown:initialValue', 'hooks', 'plugins', 'previewHighlight', 'extendedAutolinks', 'customConvertor', 'placeholder:setPlaceholder', 'linkAttributes', 'customHTMLRenderer', 'customMarkdownRenderer', 'referenceDefinition', 'customHTMLSanitizer', 'frontMatter', 'widgetRules', 'theme']);
    }
    // splits the options that have a tuiMethod to be used on the template
    // with the {{did-update}} helper
    get tuiOptionsDescriptors() {
      return this.tuiOptions.map(d => d.split(':')).filter(([, tuiMethod]) => !!tuiMethod);
    }

    // gathers all the options to initialize TUI editor, respecting tuiOptions syntax
    get options() {
      let options = {};
      for (let o of this.tuiOptions) {
        let [optionName,, tuiOption] = o.split(':');
        tuiOption = tuiOption ? tuiOption : optionName;
        let value = this.args[optionName];
        if (value !== undefined) {
          options[tuiOption] = value;
        }
      }
      return options;
    }
    async setupEditor(element) {
      await (0, _loadLocaleFile.default)(this.options.language);
      const {
        Editor
      } = await emberAutoImportDynamic("@toast-ui/editor");
      this.editor = new Editor.factory(Object.assign(this.options, {
        el: element,
        events: {
          load: (...args) => this.eventInvoked('onLoad', ...args),
          change: (...args) => this.eventInvoked('onChange', this.editor?.getMarkdown(), ...args),
          caretChange: (...args) => this.eventInvoked('onCaretChange', ...args),
          focus: (...args) => this.eventInvoked('onFocus', ...args),
          blur: (...args) => this.eventInvoked('onBlur', ...args)
        }
      }));
      this.eventInvoked('onInit', this.editor);
    }
    teardownEditor() {
      this.editor?.destroy();
    }

    // tests if an `actionName` function exists and calls it with the arguments if so
    eventInvoked(actionName, ...args) {
      this.args[actionName]?.(...args);
    }
    updateOption([optionName, tuiMethod], [value]) {
      // `value` is a special case because using `setValue`
      // moves the current cursor position so we need to avoid calling it
      // only call it when the editor value is different from the new value we got
      if (optionName === 'value' && this.editor.getMarkdown) {
        let editorValue = this.editor.getMarkdown();
        if (editorValue !== value) {
          this.editor.setMarkdown(value, false);
        }
      } else {
        (true && !(!!this.editor[tuiMethod]) && (0, _debug.assert)(`Editor instance should be have a function '${tuiMethod}' but found ${this.editor[tuiMethod]} instead.`, !!this.editor[tuiMethod]));
        this.editor[tuiMethod].call(this.editor, value);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "editor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teardownEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateOption"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TuiEditor);
});