define("ember-svg-jar/inlined/component-inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 13.429a.554.554 0 01-.178.404.63.63 0 01-.43.167H3.608a.63.63 0 01-.43-.167.554.554 0 01-.179-.404V10h4.26v.571c.002.303.13.593.359.808.228.214.537.334.86.335h3.043c.322 0 .631-.121.86-.335.228-.215.356-.505.357-.808V10H17v3.429zM3 10l1.955-3.667a.64.64 0 01.222-.243A.569.569 0 015.48 6h9.04c.107 0 .211.031.303.09a.641.641 0 01.223.243L17 10\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});