define("ember-svg-jar/inlined/message-notapproved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M43.36 43.5a3 3 0 01-2.984 3H7.56a3 3 0 01-2.984-3v-3H43.36zM38.884 28.5h-5.966c-3.892 0-5.058-2.544-5.058-5.478 0-4.892 4.864-7.856 4.864-12.718a8.756 8.756 0 10-17.512 0c0 4.862 4.864 7.826 4.864 12.718 0 2.934-1.166 5.478-5.058 5.478H7.56c-7.2 0-7.214 12-4.022 12H44.4c3.104 0 3.864-12-5.516-12z\" stroke-width=\"3\"/></g>",
    "attrs": {
      "viewBox": "0 0 56 56",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "currentColor",
      "stroke-width": "1.5",
      "class": "mr-1.5 h-5 w-5"
    }
  };
});